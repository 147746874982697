#simplex-form,
#checkout-element {
  width: 100%;
  height: 100%;
}

#checkout-element > iframe {
  max-height: 100%;
  min-height: 80vh;
}
