/* purgecss start ignore */

.logoStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

#simplex-form,
#checkout-element {
  width: 100%;
  height: 100%;
}

#checkout-element > iframe {
  max-height: 100%;
  min-height: 80vh;
}

/* purgecss end ignore */

/* @tailwind base; */

.container {
  width: 100%;
}

@media (min-width: 425px) {
  .container {
    max-width: 425px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.top-0 {
  top: 0px;
}

.top-3 {
  top: 0.75rem;
}

.right-0 {
  right: 0px;
}

.right-2 {
  right: 0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.right-2\.5 {
  right: 0.625rem;
}

.left-0 {
  left: 0px;
}

.left-2 {
  left: 0.5rem;
}

.left-2\.5 {
  left: 0.625rem;
}

.z-0 {
  z-index: 0;
}

.z-impermaxSpeedDial {
  z-index: 1050;
}

.z-impermaxModal {
  z-index: 1300;
}

.z-impermaxTooltip {
  z-index: 1500;
}

.order-4 {
  order: 4;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.m-0 {
  margin: 0px;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.ml-0 {
  margin-left: 0px;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-auto {
  margin-left: auto;
}

.-ml-px {
  margin-left: -1px;
}

.\!ml-3 {
  margin-left: 0.75rem !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-18 {
  height: 4.5rem;
}

.h-full {
  height: 100%;
}

.max-h-72 {
  max-height: 18rem;
}

.w-1 {
  width: 0.25rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-40 {
  width: 10rem;
}

.w-3\/5 {
  width: 60%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-container {
  max-width: 1200px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-tokenModalLayout {
  grid-template-columns: 2fr 1fr 20px;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-1\.5 {
  column-gap: 0.375rem;
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 20px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.first\:rounded-l:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.last\:rounded-r:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-transparent {
  border-color: transparent;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-impermaxCornflower {
  --tw-border-opacity: 1;
  border-color: rgba(151, 190, 220, var(--tw-border-opacity));
}

.border-dividerColor {
  border-color: rgba(255, 255, 255, 0.1);
}

.border-gold {
  --tw-border-opacity: 1;
  border-color: rgba(204, 177, 117, var(--tw-border-opacity));
}

.focus\:border-impermaxJade-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 225, 199, var(--tw-border-opacity));
}

.focus\:border-impermaxAstral-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(171, 199, 215, var(--tw-border-opacity));
}

.focus\:border-impermaxMirage-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(160, 163, 169, var(--tw-border-opacity));
}

.focus\:border-primary-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 225, 199, var(--tw-border-opacity));
}

.focus\:border-secondary-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(247, 187, 187, var(--tw-border-opacity));
}

.dark .dark\:border-darkDivider {
  border-color: rgba(255, 255, 255, 0.1);
}

.border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-impermaxJade-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 248, 241, var(--tw-bg-opacity));
}

.bg-impermaxJade-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 163, 104, var(--tw-bg-opacity));
}

.bg-impermaxCarnation-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 238, 238, var(--tw-bg-opacity));
}

.bg-impermaxAstral-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 104, 139, var(--tw-bg-opacity));
}

.bg-impermaxMirage-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 22, 35, var(--tw-bg-opacity));
}

.bg-impermaxCornflower-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 239, 246, var(--tw-bg-opacity));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 183, 139, var(--tw-bg-opacity));
}

.bg-modalItemActive {
  background-color: rgba(255, 255, 255, 0.05);
}

.bg-dark2 {
  background-color: rgba(255, 255, 255, 0.15);
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-impermaxJade-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 136, 86, var(--tw-bg-opacity));
}

.hover\:bg-impermaxAstral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 86, 116, var(--tw-bg-opacity));
}

.hover\:bg-impermaxMirage-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 18, 29, var(--tw-bg-opacity));
}

.hover\:bg-modalItemHover:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .dark\:bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

.dark .dark\:bg-dark2 {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .dark\:hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.hover\:bg-opacity-5:hover {
  --tw-bg-opacity: 0.05;
}

.dark .dark\:hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-modalBg {
  background-image: linear-gradient(180deg, rgba(54, 61, 77, 0.35) 0%, rgba(49, 56, 69, 0.35) 49.48%, rgba(43, 48, 60, 0.35) 69.79%, rgba(28, 30, 39, 0.35) 100%);
}

.bg-lightBlue {
  background-image: linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%);
}

.bg-primaryGold {
  background-image: linear-gradient(91.08deg, #39404E 1.23%, #373F4C 100%);
}

.bg-dark3 {
  background-image: linear-gradient(91.08deg, #39404E 1.23%, #373F4C 100%);
}

.from-rangeActive {
  --tw-gradient-from: rgb(204, 177, 117, 0.3);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 177, 117, 0));
}

.to-rangeActive {
  --tw-gradient-to: rgb(204, 177, 117, 0.3);
}

.p-0 {
  padding: 0px;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-title {
  font-family: IBM Plex Sans, sans-serif;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.ordinal {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.ordinal {
  --tw-ordinal: ordinal;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(13, 146, 149, var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.text-impermaxBlackHaze-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 254, 254, var(--tw-text-opacity));
}

.text-impermaxJade-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 163, 104, var(--tw-text-opacity));
}

.text-impermaxJade-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 109, 69, var(--tw-text-opacity));
}

.text-impermaxCarnation-800 {
  --tw-text-opacity: 1;
  color: rgba(141, 50, 50, var(--tw-text-opacity));
}

.text-impermaxCarnation {
  --tw-text-opacity: 1;
  color: rgba(235, 84, 84, var(--tw-text-opacity));
}

.text-impermaxAstral-400 {
  --tw-text-opacity: 1;
  color: rgba(109, 157, 184, var(--tw-text-opacity));
}

.text-impermaxAstral {
  --tw-text-opacity: 1;
  color: rgba(46, 115, 154, var(--tw-text-opacity));
}

.text-impermaxEmerald {
  --tw-text-opacity: 1;
  color: rgba(13, 146, 149, var(--tw-text-opacity));
}

.text-impermaxInchWorm {
  --tw-text-opacity: 1;
  color: rgba(204, 177, 117, var(--tw-text-opacity));
}

.text-impermaxGoldTips {
  --tw-text-opacity: 1;
  color: rgba(204, 177, 117, var(--tw-text-opacity));
}

.text-impermaxTreePoppy {
  --tw-text-opacity: 1;
  color: rgba(223, 80, 71, var(--tw-text-opacity));
}

.text-impermaxTrinidad {
  --tw-text-opacity: 1;
  color: rgba(223, 80, 71, var(--tw-text-opacity));
}

.text-impermaxMilanoRed {
  --tw-text-opacity: 1;
  color: rgba(223, 80, 71, var(--tw-text-opacity));
}

.text-gold {
  --tw-text-opacity: 1;
  color: rgba(204, 177, 117, var(--tw-text-opacity));
}

.text-textPrimary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-textSecondary {
  color: rgba(255, 255, 255, 0.3);
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 199, var(--tw-text-opacity));
}

.text-main {
  --tw-text-opacity: 1;
  color: rgba(213, 183, 139, var(--tw-text-opacity));
}

.text-textDark {
  --tw-text-opacity: 1;
  color: rgba(57, 64, 78, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-impermaxBlackHaze-600:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 221, 222, var(--tw-text-opacity));
}

.hover\:text-textPrimary:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-darkSecondary:hover {
  color: rgba(255, 255, 255, 0.3);
}

.hover\:text-main:hover {
  --tw-text-opacity: 1;
  color: rgba(213, 183, 139, var(--tw-text-opacity));
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:text-textSecondary {
  color: rgba(255, 255, 255, 0.3);
}

.dark .dark\:text-darkSecondary {
  color: rgba(255, 255, 255, 0.3);
}

.dark .dark\:text-accent {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 199, var(--tw-text-opacity));
}

.dark .dark\:text-darkGrey {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .dark\:text-main {
  --tw-text-opacity: 1;
  color: rgba(213, 183, 139, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-textSecondary:hover {
  color: rgba(255, 255, 255, 0.3);
}

.text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.dark .dark\:text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-modal {
  --tw-shadow: 0px 38px 54px rgba(0, 0, 0, 0.13), 0px 7.6px 8.775px rgba(0, 0, 0, 0.065);;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-numericInput {
  --tw-shadow: 0px 7px 38px rgba(0, 0, 0, 0.05), 0px 3.54375px 16.5656px rgba(0, 0, 0, 0.03375), 0px 1.4px 6.175px rgba(0, 0, 0, 0.025), 0px 0.30625px 2.19688px rgba(0, 0, 0, 0.01625);;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-header {
  --tw-shadow: 0px 30.24px 33.7824px rgba(0, 0, 0, 0.05616), 0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768);;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-connectFooter {
  --tw-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 50.625px 34.875px rgba(0, 0, 0, 0.02025), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 4.375px 4.625px rgba(0, 0, 0, 0.00975);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-btnSm {
  --tw-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-select {
  --tw-shadow: 0px 29px 80px rgba(0, 0, 0, 0.2), 0px 14.6812px 34.875px rgba(0, 0, 0, 0.135), 0px 5.8px 13px rgba(0, 0, 0, 0.1), 0px 1.26875px 4.625px rgba(0, 0, 0, 0.065);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-impermaxJade-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 237, 220, var(--tw-ring-opacity));
}

.focus\:ring-impermaxAstral-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 220, 230, var(--tw-ring-opacity));
}

.focus\:ring-impermaxMirage-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 197, 201, var(--tw-ring-opacity));
}

.focus\:ring-primary-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 237, 220, var(--tw-ring-opacity));
}

.focus\:ring-secondary-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 212, 212, var(--tw-ring-opacity));
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(8px);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
}

.grayscale {
  --tw-grayscale: grayscale(100%);
}

.invert {
  --tw-invert: invert(100%);
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-shadow: none;
}

@media (min-width: 425px) {
  .sm\:order-none {
    order: 0;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-2\/6 {
    width: 33.333333%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:gap-0 {
    gap: 0px;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-4\/6 {
    width: 66.666667%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-3 {
    gap: 0.75rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .lg\:top-0 {
    top: 0px;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-2\/6 {
    width: 33.333333%;
  }

  .lg\:max-w-search {
    max-width: 260px;
  }

  .lg\:grid-cols-layout {
    grid-template-columns: 220px 1fr;
  }

  .lg\:grid-cols-labelsLayout {
    grid-template-columns: 106px 1fr 1fr;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-1 {
    gap: 0.25rem;
  }

  .lg\:rounded-md {
    border-radius: 20px;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:mr-36 {
    margin-right: 9rem;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 960px) {
  .container\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (min-width: 1920px) {
  .xxl\:grid-cols-largeLayout {
    grid-template-columns: 220px 1fr 220px;
  }
}

/* purgecss start ignore */

.basic-single{
  z-index:10;
  border-radius:20px
}

/* purgecss end ignore */
